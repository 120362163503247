<template>
  <div>
    <van-steps direction="vertical" :active="0">
      <van-step v-for="item in timelineList" :key="item.id">
        <h3>【{{ item.operation }}】{{ item.createEmployeeName }}</h3>
        <p>{{ item.createTime }}</p>
      </van-step>
    </van-steps>
    <van-empty v-if="timelineList.length === 0" description="暂无记录" />
  </div>
</template>

<script>
import { fetchTaskTimelineDetail } from '@/services/task';
export default {
  name: 'task-timeline',
  data() {
    return {
      timelineList: [],
    }
  },
  methods: {
    async loadTimelineDetail() {
      try {
        const res = await fetchTaskTimelineDetail(this.$route.params.taskId);
        if (res.code === 0) {
          this.timelineList = res.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    this.loadTimelineDetail();
  },
}
</script>
