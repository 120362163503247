import request from '@/utils/request';

/**
 * 获取保养/维护/巡检单的操作详情
 * @param {*} id
 * @returns
 */
export async function fetchTaskTimelineDetail(taskId) {
  return request(`/v3/mp/common/operationDetails?taskId=${taskId}`);
}
